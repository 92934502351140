body {
    margin: 0;
    font-family:  'Arial';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  'Arial';
}
